export default {
  sandwichs: [
    {
      id: '001',
      korName: '햄 치즈 치킨 샌드위치',
      engName: '',
      imageUri: require('../../assets/images/menu/food/sandwich/001.png'),
      price: 7500,
      desc: '부드러운 식빵에 숙성 로인햄과 직화 닭가슴살, 에멘탈 치즈, 토마토, 로메인을 더한 샌드위치',
    },
    {
      id: '005',
      korName: '치즈크루아상',
      engName: 'cheese croissant',
      imageUri: require('../../assets/images/menu/food/sandwich/005.png'),
      price: 4200,
      desc: '풍미 가득한 크루아상에 에멘탈 치즈가 샌드된 크루아상 샌드위치',
    },
    {
      id: '006',
      korName: '초리조&에그 크루아상',
      engName: 'chorizo & egg croissant',
      imageUri: require('../../assets/images/menu/food/sandwich/006.png'),
      price: 5500,
      desc:
        '이베리코 흑돼지 초리조와 에그 샐러드, 에멘탈 치즈, 쎄미 드라이 토마토에 로메인의 아삭한 식감이 더해진 크루아상 샌드위치',
    },
    {
      id: '007',
      korName: '로인햄&에멘탈 크루아상',
      engName: 'loin ham & emmental croissant',
      imageUri: require('../../assets/images/menu/food/sandwich/007.png'),
      price: 5500,
      desc: '로인햄, 에멘탈 치즈, 쎄미 드라이 토마토에 로메인의 아삭한 식감이 더해진 크루아상 샌드위치',
    },
  ],
  kitchens: [
    {
      id: '001',
      korName: '양송이 수프',
      engName: 'mushroom soup',
      price: 10500,
      desc: '양송이 버섯이 들어간 진한 풍미의 크림 수프',
      imageUri: require('../../assets/images/menu/food/kitchen/001.png'),
    },
    {
      id: '016',
      korName: '콥 샐러드',
      engName: 'cobb salad',
      price: 18000,
      desc:
        '닭 가슴살, 아보카도, 삶은 계란, 대추 방울토마토, 올리브와 구운 버섯으로 맛을 낸 콥 샐러드 고소한 랜치 드레싱이 함께 제공됩니다.',
      imageUri: require('../../assets/images/menu/food/kitchen/016.png'),
    },
    {
      id: '017',
      korName: '부라타치즈 샐러드',
      engName: 'burrata salad',
      price: 19000,
      desc:
        '신선한 프리세, 우유크림 베이스로 만들어진 부드럽고 크리미한 부라타치즈, 마리네이드 한 청포도와 대추 방울토마토를 곁들인 샐러드 달콤하고 상큼한 포도향이 특징인 발사믹 비네거 드레싱이 함께 제공됩니다.',
      imageUri: require('../../assets/images/menu/food/kitchen/017.png'),
    },
    {
      id: '018',
      korName: '호박고지 봉골레 파스타',
      engName: 'pasta with vongole and dried zucchini',
      price: 19000,
      desc: '호박고지와 신선한 문어 바지락, 마늘을 넣어 깔끔하고 단백한 맛이 특징인 링귀네 오일 파스타',
      imageUri: require('../../assets/images/menu/food/kitchen/018.png'),
    },
    {
      id: '019',
      korName: '명란 오일 파스타',
      engName: 'oil pasta with pollock roe(myeongnan)',
      price: 19000,
      desc: '마늘과 신선한 명란, 튀긴 샬롯을 더해 명란의 고소함과 깊은 마늘의 풍미를 느낄 수 있는 링귀네 오일 파스타',
      imageUri: require('../../assets/images/menu/food/kitchen/019.png'),
    },
    {
      id: '020',
      korName: '구운 갑오징어 리소토',
      engName: 'black risotto with cuttlefish',
      price: 19000,
      desc:
        '구운 갑오징어와 케이퍼 베리, 먹물 크림 소스로 맛을 낸 세미 디 오르조 리소토 (세미 디오르조는 이탈리아어로 보리씨라는 뜻으로 큰 쌀알이나 보리알 모양을 한 파스타 입니다.)',
      imageUri: require('../../assets/images/menu/food/kitchen/020.png'),
    },
    {
      id: '021',
      korName: '세가지 치즈 토마토 파스타',
      engName: 'three cheese pasta with tomato sauce',
      price: 21000,
      desc: '브리치즈와 그라나파다노치즈를 넣은 토마토소스에 미니 부라타치즈를 토핑으로 더한 링귀네 토마토 파스타',
      imageUri: require('../../assets/images/menu/food/kitchen/021.png'),
    },
    {
      id: '022',
      korName: '블랙 트러플 라비올리',
      engName: 'black truffle ravioli with creamy sauce',
      price: 22000,
      desc:
        '리코타와 시금치로 속을 채운 라비올리에 블랙 트러플 오일로 마무리하여 크리미하고 녹진한 크림소스가 특징인 파스타',
      imageUri: require('../../assets/images/menu/food/kitchen/022.png'),
    },
    {
      id: '023',
      korName: '머쉬룸 오믈렛 라이스',
      engName: 'mushroom omelette rice with demi-glace sauce',
      price: 18000,
      desc: '머쉬룸 데미그라스 소스와 함께 즐기는 영양 가득한 오믈렛 라이스',
      imageUri: require('../../assets/images/menu/food/kitchen/023.png'),
    },
    {
      id: '024',
      korName: '믹스베리 팬케이크(w/ 밀크 크림)',
      engName: 'mixed berry pancakes with milk cream',
      price: 17000,
      desc: '달콤하고 상큼한 믹스베리에 수제로 만든 밀크 크림을 곁들인 팬케이크',
      imageUri: require('../../assets/images/menu/food/kitchen/024.png'),
    },
    {
      id: '025',
      korName: '프렌치 오믈렛',
      engName: 'french omelette',
      price: 18000,
      desc:
        '믹스드 머쉬룸, 이베리코 초리조와 와일드 루꼴라를 곁들인 프렌치 스타일 오믈렛 (신선한 프리세 샐러드와 바닐라빈 토마토 피클이 함께 제공됩니다.)',
      imageUri: require('../../assets/images/menu/food/kitchen/025.png'),
    },
  ],
};
